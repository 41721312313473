import { useState, useEffect } from "react";

export default function Component() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 500); // Delay for the text visibility
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black relative">
      {/* Fullscreen image with opacity */}
      <img
        src="/Hacked.jpg" // Replace with your actual image path
        alt="Hacked"
        className="absolute inset-0 w-full h-full object-cover transition-opacity duration-1000"
        style={{ opacity: visible ? 0.3 : 1 }} // Change opacity dynamically
      />

      {/* Centered content */}
      <div
        className={`z-10 text-center transition-opacity duration-1000 ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        role="alert"
        aria-live="polite"
      >
        <h1 className="text-6xl font-bold mb-8 animate-pulse">
          <span className="text-red-500">C</span>
          <span className="text-yellow-500">o</span>
          <span className="text-green-500">n</span>
          <span className="text-blue-500">g</span>
          <span className="text-indigo-500">r</span>
          <span className="text-purple-500">a</span>
          <span className="text-pink-500">t</span>
          <span className="text-red-500">u</span>
          <span className="text-yellow-500">l</span>
          <span className="text-green-500">a</span>
          <span className="text-blue-500">t</span>
          <span className="text-indigo-500">i</span>
          <span className="text-purple-500">o</span>
          <span className="text-pink-500">n</span>
          <span className="text-red-500">s</span>
          <span className="text-yellow-500">!</span>
        </h1>
        <p className="text-white text-2xl mb-8 animate-bounce">
          You've been hacked! 🎉
        </p>
      </div>
    </div>
  );
}
