import axios from "axios";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../Firebase";

export default function Component() {
  const navigate = useNavigate();
  const getAuthData = async () => {
    await signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user.displayName);
        console.log(result.user.email);
        const user = {
          user: result?.user?.displayName,
          email: result?.user?.email,
        };
        (async function () {
          await axios
            .post("api/login", {
              email: result.user.email,
              user: result.user.displayName,
            })
            .then((res) => {
              console.log(res.data.employee);
              navigate("/hacked");
            })
            .catch((err) => console.log(err));
        })();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md">
        <div className="flex justify-center">
          <img
            src="https://images.ctfassets.net/3fcisxc3a6xz/docusign_logo_black_text_on_white_0.png/90872cd475f92acafc7c490c93976e40/ds-logo-on-white.png"
            alt="DocuSign Logo"
            className="h-12"
          />
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
          Welcome to DocuSign
        </h2>
        <p className="mt-2 text-sm text-center text-gray-600">
          <span className="font-bold">Sign in to your account</span>
        </p>
        <div className="mt-8">
          {/* <GoogleLogin
            onSuccess={(credentialResponse) => {
              // const decode = jwtDecode(credentialResponse.credential);

              console.log(credentialResponse);
              // const { email, name } = decode;
              // onsubmit(email, name);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          /> */}
          <div className="flex justify-center">
            <button
              onClick={getAuthData}
              type="submit"
              className="bg-transparent hover:bg-gradient-to-r hover:from-purple-500 hover:to-orange-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              Sign In With Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
