// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// import { getAuth } from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBuU9JG5-OZl0MOYdmkNmbx_Q9GcwOXKgI",
  authDomain: "phishing-5ac9b.firebaseapp.com",
  projectId: "phishing-5ac9b",
  storageBucket: "phishing-5ac9b.appspot.com",
  messagingSenderId: "358187567138",
  appId: "1:358187567138:web:0f18b92f5c3e86d983e27b",
  measurementId: "G-RNBN026LNY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;

export const provider = new GoogleAuthProvider();

// export const signInWithGoogle = async () => {
//   signInWithPopup(auth, provider)
//     .then((result) => {
//       //   //   console.log(result);
//       //   const name = result.user.displayName;
//       //   const email = result.user.displayName;
//       //   const profilePhoto = result.user.photoURL;
//       //   localStorage.setItem("name", name);
//       //   localStorage.setItem("email", email);
//       //   localStorage.setItem("pic", profilePhoto);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
